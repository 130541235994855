Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/@scandipwa/partnerships/src/plugin/Config.plugin.js'),
require('./../packages/@scandipwa/partnerships/src/plugin/MyAccountOverlay.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/AdditionalRoutes.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/GTMHocWrapper.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/StoreModifications.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/AddShippingAndPaymentInfo.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/AddToWishlist.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/CategorySortChange.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/SegmentifySearch.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/addToCart.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/addressOverlay.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/cartItemChangeQuantity.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/cartItemRemove.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/cartPage.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/checkout.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/checkoutOption.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/impressions.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/myAccountLoginRegister.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/onSiteSearch.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/orderPageDataUpdate.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/productClick.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/productDetail.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/productDetailQtyChange.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/productsPageDataUpdate.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/promotion.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/purchase.plugin.js'),
require('./../packages/@scandipwa/gtm/src/plugin/event-dispatches/subscription.plugin.js'),
require('./../packages/@scandipwa/tabby/src/plugin/Checkout.container.plugin.js'),
require('./../packages/@scandipwa/tabby/src/plugin/Checkout.query.plugin.js'),
require('./../packages/@scandipwa/tabby/src/plugin/Router.component.plugin.js'),
require('./../packages/@scandipwa/tamara/src/plugin/Checkout.container.plugin.js'),
require('./../packages/@scandipwa/tamara/src/plugin/Checkout.query.plugin.js'),
require('./../packages/@scandipwa/tamara/src/plugin/Router.component.plugin.js'),
require('./../packages/@scandipwa/shipmentstracking/src/plugin/Router.component.plugin.js'),
require('./../packages/@scandipwa/nationalidnumber/src/plugin/CheckoutShippingBillingComponent.plugin.js'),
require('./../packages/@scandipwa/nationalidnumber/src/plugin/CheckoutShippingBillingContainer.plugin.js'),
require('./../packages/@scandipwa/nationalidnumber/src/plugin/CheckoutShippingComponent.plugin.js'),
require('./../packages/@scandipwa/nationalidnumber/src/plugin/CheckoutShippingContainer.plugin.js'),
require('./../packages/@scandipwa/nationalidnumber/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@scandipwa/customsduty/src/plugin/CartOverlay.plugin.js'),
require('./../packages/@scandipwa/customsduty/src/plugin/CartPage.plugin.js'),
require('./../packages/@scandipwa/customsduty/src/plugin/CartQuery.plugin.js'),
require('./../packages/@scandipwa/customsduty/src/plugin/CheckoutOrderSummary.plugin.js'),
require('./../packages/@scandipwa/customsduty/src/plugin/MyAccountOrderPopupComponent.plugin.js'),
require('./../packages/@scandipwa/customsduty/src/plugin/OrderQuery.plugin.js'),
require('./../packages/@scandipwa/cjpublicis/src/plugin/CheckoutSucess.plugin.js'),
require('./../packages/@scandipwa/cjpublicis/src/plugin/addCookieToRouter.plugin.js'),
require('./../packages/@scandipwa/cityselector/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/Checkout.container.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/Checkout.query.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/CheckoutAddressTable.component.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/CheckoutBilling.component.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/CheckoutNewAddressForm.component.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/CheckoutPayments.component.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/CheckoutPayments.container.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/CheckoutShippingBilling.container.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/NavigationTabs.container.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/Router.component.plugin.js'),
require('./../packages/@scandipwa/checkoutcom/src/plugin/UrlHandler.plugin.js'),
require('./../packages/@scandipwa/adyen-payments/src/plugin/AddToCartPopup.component.plugin.js'),
require('./../packages/@scandipwa/adyen-payments/src/plugin/App.plugin.js'),
require('./../packages/@scandipwa/adyen-payments/src/plugin/Billing.plugin.js'),
require('./../packages/@scandipwa/adyen-payments/src/plugin/CartOverlay.component.plugin.js'),
require('./../packages/@scandipwa/adyen-payments/src/plugin/CartPage.component.plugin.js'),
require('./../packages/@scandipwa/adyen-payments/src/plugin/CheckoutShippingBilling.component.plugin.js'),
require('./../packages/@scandipwa/adyen-payments/src/plugin/ConfigDispatcher.plugin.js'),
require('./../packages/@scandipwa/adyen-payments/src/plugin/Payments.plugin.js'),
require('./../packages/@scandipwa/adyen-payments/src/plugin/Success.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { render } from 'react-dom';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Style/main';

// Remove already registered service workers
navigator.serviceWorker.getRegistrations().then(
/** @namespace Scandipwa/Index/getRegistrations/then */
    (registrations) => {
        if (registrations.length > 0) {
            console.log('Removing service worker.');

            // eslint-disable-next-line no-restricted-syntax
            for (const registration of registrations) {
                registration.unregister();
            }

            // eslint-disable-next-line no-magic-numbers
            setTimeout(() => location.reload(), 1000);
        }
    }
);

render(<App />, document.getElementById('root'));
